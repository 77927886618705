/*
TABSQUARE SMARTPAY
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 14, 2022
UPDATE DATE: Oct 14, 2022
REVISION: 1
NAME: variable.scss
TYPE: SASS Cascading Style Sheet
DESCRIPTION: SmartPay | ...
PALETTE:    1. #FF6B00 / rgb(255, 107, 0) (Pure Orange / Blaze Orange)
            2. #FFA800 / rgb(255, 168, 0) (Pure (or mostly pure) Orange / Web Orange)
            3. #589C00 / rgb(88, 156, 0) (Dark Green - Lime Green / Limeade)
            4. #0C8ED0 / rgb(12, 142, 208) (Strong Blue / Cerulean)
            5. #D10000 / rgb(209, 0, 0) (Strong Red / Guardsman Red)
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@import './variable.scss';

@font-face{
  font-family: 'Axiforma';
  src: url('./../font/Axiforma-Regular.woff2') format('woff2'),
       url('./../font/Axiforma-Regular.woff') format('woff'),
       url('./../font/Axiforma-Regular.ttf') format('ttf'),
       url('./../font/Axiforma-Regular.eot') format('eot'),
       url('./../font/Axiforma-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Axiforma';
  src: url('./../font/Axiforma-Bold.woff2') format('woff2'),
       url('./../font/Axiforma-Bold.woff') format('woff'),
       url('./../font/Axiforma-Bold.ttf') format('ttf'),
       url('./../font/Axiforma-Bold.eot') format('eot'),
       url('./../font/Axiforma-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Axiforma';
  src: url('./../font/Axiforma-SemiBold.woff2') format('woff2'),
       url('./../font/Axiforma-SemiBold.woff') format('woff'),
       url('./../font/Axiforma-SemiBold.ttf') format('ttf'),
       url('./../font/Axiforma-SemiBold.eot') format('eot'),
       url('./../font/Axiforma-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Axiforma';
  src: url('./../font/Axiforma-Thin.woff2') format('woff2'),
       url('./../font/Axiforma-Thin.woff') format('woff'),
       url('./../font/Axiforma-Thin.ttf') format('ttf'),
       url('./../font/Axiforma-Thin.eot') format('eot'),
       url('./../font/Axiforma-Thin.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body{
  background: rgba($colorLabelRGB, .5);
  color: $colorText;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Axiforma', Helvetica, Arial, sans-serif;
  font-feature-settings: 'ss02';
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: $colorPrimary;
  @extend %transition;

  &:hover{
    color: $colorPrimaryHover;
  }
  
  &[disabled]{
    pointer-events: none;
    opacity: .5;
  }
}

.link-look{
  color: $colorLabel;

  > span{
    position: relative;

    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3px;
      border-bottom: 1px dashed rgba($colorLabelRGB, .5);
    }
  }

  &:hover{
    color: $colorPrimary;
  }

  > svg{
    width: 16px;
    height: 16px;
    margin: -3px 5px -2px 0;
  }
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="file"],
textarea,
button{
  display: block;
  color: $colorText;
  font-size: 16px;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  background: transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: 'Axiforma', Helvetica, Arial, sans-serif;
  @extend %transition;

  &:focus{
    outline: none;
  }

  &:disabled,
  &[disabled]{
    pointer-events: none;
    opacity: .5;
  }

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button{
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;
  }
}

input[type="number"]{
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

svg{
  display: inline-block;
  vertical-align: middle;
}

section{
  padding: $spacing;
}

ul.floating{
  overflow: auto;
  margin: -($spacingDiv2);

  > li{
    float: left;
    padding: $spacingDiv2;
    box-sizing: border-box;
    width: 100%;

    @for $i from 1 through 99{
      &.lay-#{$i} { 
        width: $i * 1%;
      }
    }
    
    &.lay-333{
      width: 33.33%;
    }
    
    &.lay-auto{
      width: auto;
    }

    &.anim-enter{
      @extend %anim-enter;
    }
    &.anim-enter-active{
      @extend %anim-enter-active;
    }
    &.anim-enter-done{
      @extend %anim-enter-done;
    }
    &.anim-exit{
      @extend %anim-exit;
    }
    &.anim-exit-active{
      @extend %anim-exit-active;
    }
  }
}

ul.forming{
  > li{
    position: relative;

    label{
      &.text{
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        color: $colorLabel;
        left: $spacing;
        font-size: 14px;
        line-height: 18px;
        background: #FFF;
        padding: 0 $spacingDiv2;
        pointer-events: none;
        z-index: 1;
        @extend %transition;
      }

      &.toggle{
        display: block;
        cursor: pointer;
        position: relative;
        padding: 0 0 0 30px;
        line-height: 16px;

        > div{
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          border: 1px solid rgba($colorLabelRGB, .25);
          border-radius: 5px;
          background: #FFF;
          box-sizing: border-box;
          @extend %transition;

          > svg{
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            color: #FFF;
            height: 8px;
            margin: 0 auto;
          }
        }

        > input{
          position: absolute;
          left: 0;
          top: 0;

          &:checked{
            ~ div{
              border: 1px solid $colorPrimary;
              background: $colorPrimary;
            }
          }
        }

        &:hover{
          > div{
            border: 1px solid rgba($colorLabelRGB, .5);
          }
        }
      }
    }

    .input-notif{
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 18px;
      top: 50%;
      transform: translate(0, -50%);
      right: $spacingMul15;
      color: #FFF;
      background: $colorLabel;
      @extend %transition;

      &.success{
        background: $colorSuccess;
      }

      &.warning{
        background: $colorWarning;
        > svg{
          height: 11px;
          // margin: -1px auto 0 auto;
        }
      }

      &.error{
        background: $colorError;
      }

      > svg{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 6px;
        transform: translate(0, -50%);
      }
    }
  
    input[type="text"],
    input[type="password"],
    input[type="number"]{
      border: 1px solid rgba($colorLabelRGB, .25);
      border-radius: $radiusIn;
      height: 50px;
      padding: 0 $spacing;
      // font-size: 16px;

      &:hover{
        border: 1px solid rgba($colorLabelRGB, .5);
      }

      &:focus{
        border: 1px solid $colorPrimary;
      }

      &.input-buzz{
        animation: inputBuzz 4s infinite ease-in-out;
      }
    }

    .label-left{
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: $spacingDiv2 - 3;
      // width: 60px;
      padding: 0 0 0 $spacing;
      color: $colorLabel;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      // box-sizing: border-box;
    }

    &.has-label-left{
      input[type="text"],
      input[type="password"],
      input[type="number"]{
        padding: 0 $spacing 0 ($spacingDiv2 + 50);
      }
    }

    &.is-focus{
      label{
        &.text{
          font-size: 12px;
          line-height: 12px;
          top: $spacingDiv6;
          transform: translate(0, 0);
          padding: 0 $spacingDiv4;
          left: $spacingMul125;
        }
      }
    }

    .react-select{
      z-index: 0;

      .react-select__control{
        border: 1px solid rgba($colorLabelRGB, .25);
        border-radius: $radiusIn;
        min-height: 50px;
        box-shadow: none;

        &:hover{
          border: 1px solid rgba($colorLabelRGB, .5);
        }

        // &.react-select__control--is-focused{}

        &.react-select__control--menu-is-open{
          border: 1px solid $colorPrimary;
          &:hover{
            border: 1px solid $colorPrimary;
          }
        }

        .react-select__value-container{
          padding: 0 $spacing;

          .react-select__placeholder{
            font-size: 12px;
            line-height: 16px;
            color: $colorText;
          }

          .react-select__single-value{
            // font-size: 14px;
            // line-height: 18px;
            font-size: 16px;
            line-height: 20px;
            margin: 0;

            > svg{
              display: none;
            }
          }

          .react-select__input-container{
            padding: 0;
            margin: 0;
            // font-size: 14px;
            // line-height: 18px;
            font-size: 16px;
            line-height: 20px;

            // .react-select__input{}
          }
        }

        .react-select__indicators{
          .react-select__indicator-separator{
            margin: $spacingDiv15 0;
          }

          .react-select__indicator{
            cursor: pointer;
          }
        }
      }
    }
  }
}

@keyframes inputBuzz{
  0% { border: 1px solid rgba($colorLabelRGB, .25); box-shadow: 0 0 0 0 rgba($colorErrorRGB, 0), inset 0 0 0 0 rgba($colorErrorRGB, 0); }
  12% { border: 1px solid rgba($colorErrorRGB, 1); box-shadow: 0 0 0 2px rgba($colorErrorRGB, .25), inset 0 0 0 2px rgba($colorErrorRGB, .25); }
  25% { border: 1px solid rgba($colorLabelRGB, .25); box-shadow: 0 0 0 0 rgba($colorErrorRGB, 0), inset 0 0 0 0 rgba($colorErrorRGB, 0); }
  37% { border: 1px solid rgba($colorErrorRGB, 1); box-shadow: 0 0 0 2px rgba($colorErrorRGB, .25), inset 0 0 0 2px rgba($colorErrorRGB, .25); }
  50% { border: 1px solid rgba($colorLabelRGB, .25); box-shadow: 0 0 0 0 rgba($colorErrorRGB, 0), inset 0 0 0 0 rgba($colorErrorRGB, 0); }
  62% { border: 1px solid rgba($colorErrorRGB, 1); box-shadow: 0 0 0 2px rgba($colorErrorRGB, .25), inset 0 0 0 2px rgba($colorErrorRGB, .25); }
  75% { border: 1px solid rgba($colorLabelRGB, .25); box-shadow: 0 0 0 0 rgba($colorErrorRGB, 0), inset 0 0 0 0 rgba($colorErrorRGB, 0); }
  82% { border: 1px solid rgba($colorErrorRGB, 1); box-shadow: 0 0 0 2px rgba($colorErrorRGB, .25), inset 0 0 0 2px rgba($colorErrorRGB, .25); }
  100% { border: 1px solid rgba($colorLabelRGB, .25); box-shadow: 0 0 0 0 rgba($colorErrorRGB, 0), inset 0 0 0 0 rgba($colorErrorRGB, 0); }
}

.react-select__menu-portal{
  z-index: 1000 !important;
  // position: relative !important;

  .react-select__menu{
    // box-shadow: none;
    border-radius: $radiusIn;
    margin: 0 0 $spacing 0;
    box-shadow: 0px 6px 21px -4px rgba(7, 7, 7, 0.1);
    overflow: hidden;

    .react-select__menu-list{
      padding: 0;

      .react-select__option{
        position: relative;
        color: $colorText;
        padding: $spacing $spacing;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
        @extend %transition;

        > .staff{
          width: 18px;
          height: 18px;
          margin: -2px 5px 0 0;
        }

        > .check{
          opacity: 0;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 15px;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background: $colorPrimary;
          color: #FFF;
          padding: 5px;
          box-sizing: border-box;
        }

        &.react-select__option--is-focused{
          background: rgba($colorLabelRGB, .05);
        }

        &.react-select__option--is-selected{
          color: $colorText;
          background: rgba($colorPrimaryRGB, .1);

          > .check{
            opacity: 1;
          }
        }
      }
    }
  }
}

.btn{
  display: block;
  position: relative;
  text-align: center;
  color: #FFF;
  background: $colorPrimary;
  border-radius: $radiusIn;
  font-weight: bold;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 $spacing;

  &:hover{
    color: #FFF;
    background: $colorPrimaryHover;
  }

  &.btn-outline{
    background: #FFF;
    border: 1px solid $colorPrimary;
    color: $colorPrimary;

    &:hover{
      color: #FFF;
      border: 1px solid $colorPrimaryHover;
      background: $colorPrimaryHover;
    }
  }

  &.btn-plain{
    background: #FFF;
    border: 1px solid rgba($colorLabelRGB, .25);
    color: $colorText;

    &:hover{
      // color: #FFF;
      border: 1px solid rgba($colorLabelRGB, .5);
      // background: $colorPrimaryHover;
    }
  }

  &.btn-loading{
    pointer-events: none;
    &[disabled]{
      background: $colorPrimary;
      color: $colorLabel;
    }
  }

  &[disabled]{
    background: $colorBg;
    color: $colorLabel;
    border: 1px solid $colorLabel;
  }

  > svg{
    margin: -3px 0 0 0;
    // width: 16px;
    height: 16px;

    &.before{
      margin-right: 5px;
    }
  }

  > ul.loading-btn{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    right: 0;

    > li{
      background: #FFF;
    }
  }
  
  &.anim-fix{
    > ul.loading-btn{
      position: static;
      transform: none;
      display: inline-block;
    }
  }

}

// .btn-skeleton{
//   position: relative;
//   height: 50px;
//   background: #CCC;
//   border-radius: $radiusIn;
//   overflow: hidden;

//   &:before{
//     content: '';
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background-image: linear-gradient( 90deg, rgba(238, 238, 238, 0), rgba(238, 238, 238, 0.5) 75%, rgba(238, 238, 238, 0) 100% );
//     background-repeat: repeat-y;
//     background-size: 50% 100%;
//     background-position: -20% 0;
//     opacity: 0;
//     animation: shine 1s infinite;
//   }
// }

// @keyframes shine{
//   0% {background-position: -20% 0;opacity: 0;}
//   35% {opacity: 1;}
//   65% {opacity: 1;}
//   100% {background-position: 120% 0;opacity: 0;}
// }

ul.loading-btn{
  margin: 0 auto;
  text-align: center;

  > li{
    display: inline-block;
    vertical-align: middle;
    background: $colorPrimary;
    height: 10px;
    width: 4px;
    margin: 0 3px;
    animation: btn-anim 0.6s infinite ease-in-out;

    &:nth-child(1){animation-delay: -0.4s;}

    &:nth-child(2){animation-delay: -0.2s;}

    &:nth-child(3){animation-delay: 0}
  }
}

@keyframes btn-anim{
  // 0% { opacity: 0; height: 0; }
  // 50% { opacity: 1; height: 20px; }
  // 100% { opacity: 0; height: 0; }
  0% { transform: translate(0, -5px); }
  50% { transform: translate(0, 5px); }
  100% { transform: translate(0, -5px); }
}

.Toastify__toast-container{
  padding: 0 !important;
  max-width: $maxWidth !important;

  &.Toastify__toast-container--top-center{
    top: 0;
  }

  > .Toastify__toast{
    padding: $spacing;
    margin: $spacing;
    background: $colorText;
    border-radius: $radiusIn;
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, .25);

    > .Toastify__toast-body{
      padding: 0;
      margin: 0;

      > .Toastify__toast-icon{
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background: $colorPrimary;

        > svg{
          color: #FFF;
          position: absolute;
          height: 12px;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      > div{
        font-family: 'Axiforma', Helvetica, Arial, sans-serif;
        // color: $colorLabel;
        color: rgba(255, 255, 255, .6);
        font-size: 12px;
        line-height: 16px;

        > span{
          display: block;
          font-size: 16px;
          line-height: 20px;
          color: #FFF;
          // font-weight: 600;
          // color: $colorPrimary;
        }
      }
    }

    > .Toastify__close-button{
      opacity: 1;
      color: #FFF;
    }

    &.Toastify__toast--success{
      > .Toastify__toast-body{
        > .Toastify__toast-icon{
          background: $colorSuccess;
        }

        // > div{
        //   > span{
        //     color: $colorSuccess;
        //   }
        // }
      }
    }

    &.Toastify__toast--error{
      > .Toastify__toast-body{
        > .Toastify__toast-icon{
          background: $colorError;
        }

        // > div{
        //   > span{
        //     color: $colorError;
        //   }
        // }
      }
    }
  }
}

.loading{
  // margin: 0 !important;
  color: $colorLabel;
  text-align: center;
  padding: $spacing;

  > svg{
    width: 50px;
    height: 50px;
    // margin: 0 0 $spacing 0;

    .rotate-1,
    .rotate-2{
      transform-origin: center center;
      animation: rotate 1.0s infinite ease-in-out;
    }

    .rotate-2{
      animation-delay: -0.5s;
    }
  }

  > span{
    display: block;
    margin-top: $spacingDiv2;
    font-size: 14px;
    line-height: 18px;
  }
}

@keyframes rotate{
  0% { opacity: 0; transform: rotate(0); }
  50% { opacity: 1; }
  100% { opacity: 0; transform: rotate(180deg); }
}

.separation-dashed{
  border-top: 1px dashed rgba($colorLabelRGB, .25);
}

header{
  position: fixed;
  left: 0;
  right: 0;
  background: #FFF;
  text-align: center;
  box-shadow: 0px 6px 11px -4px rgba(7, 7, 7, 0.05);
  z-index: 9;

  > ul.head-nav{
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translate(0, -50%);

    &.on-left{
      left: $spacing;
    }

    &.on-right{
      right: $spacing;
    }

    > li{
      padding: 0;

      > a,
      > button{
        display: block;
        color: $colorText;
        width: 24px;
        height: 24px;
        padding: 2px;
        box-sizing: border-box;

        &:hover{
          color: $colorPrimary;
        }

        &.close{
          padding: 4px;
        }

        > svg{
          display: block;
        }
      }
    }
  }

  .head-mid{
    font-size: 14px;
    line-height: 32px;
    font-weight: 600;
    margin: 8px 0;
    
    > img{
      max-width: 100px;
      height: 32px;
      object-fit: contain;
    }

    > svg{
      width: 24px;
      margin: -3px 5px 0 10px;
      border-left: 1px solid $colorLabel;
      padding: 0 0 0 10px;
    }
  }
}

.head-pad{
  height: 24px;
}

footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: $spacing $spacing 8px $spacing;
  background: #FFF;
  border-radius: $radiusOut $radiusOut 0 0;
  box-sizing: border-box;
  box-shadow: 0px -6px 11px -4px rgba(7, 7, 7, 0.05);
  z-index: 100;
  
  > div {
    padding-bottom: 16px;
  }

  > .link-look{
    color: $colorPrimary;
    margin-bottom: $spacing;
    font-size: 14px;
    line-height: 18px;

    > svg{
      width: 20px;
      height: 20px;
      margin: -3px 4px -2px 0;
    }

    &.animate{
      > svg{
        animation: rotating 1s infinite ease-in-out;
      }
    }

    &:hover{
      color: $colorPrimaryHover;
    }
  }
}

.footer-text {
  text-align: center;
  line-height: 15px;
  padding: 5px;
}

.foot-pad{
  height: 200px;

  &.with-refresh{
    height: 124px;
  }

  &.use-bg{
    height: 90 - $spacingDiv2;
  }
}

.use-max{
  background: #FFF;
  max-width: $maxWidth;
  margin: 0 auto;
  min-height: 100vh;
  box-sizing: border-box;
  // border-right: 1px solid #CCC;
  // border-left: 1px solid #CCC;

  header{
    max-width: $maxWidth;
    margin: 0 auto;
  }

  footer{
    max-width: $maxWidth;
    margin: 0 auto;
  }
}

.use-bg{
  background: $colorBg;
  // min-height: 100vh;
}

#popup-root{
  pointer-events: none !important;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-overlay{
  background: rgba(0, 0, 0, .25);
  backdrop-filter: blur(3px);
  -moz-backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  padding: 0 $spacing;

  .popup-content{
    background: #FFF;
    width: 100%;
    max-width: $maxWidth - $spacing;
    border-radius: $radiusOut;
    box-sizing: border-box;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    overflow: hidden;

    .popup-head{
      position: relative;
      padding: $spacing 54px $spacing $spacing;
      box-shadow: 0px 6px 11px -4px rgba(7, 7, 7, 0.13);
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;

      > span{
        display: block;
        margin-top: $spacingDiv4;
        color: $colorLabel;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }

      .popup-close{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 54px;
        height: 54px;
        color: $colorLabel;

        &:hover{
          color: $colorText;
        }

        > svg{
          width: 16px;
          height: 16px;
        }
      }
    }

    ul.resto-info{
      padding: $spacingDiv2 0 $spacing 0;
      margin: $spacingDiv2;
    
      > li{
        &.email,
        &.phone{
          > a{
            color: $colorText;

            &:hover{
              color: $colorPrimary;
            }
          }
        }
      }
    }

    .popup-iframe{
      height: 70vh;

      iframe{
        width: 100%;
        height: 100%;
      }
    }
  }
}

.EZDrawer{
  .EZDrawer__overlay{
    display: block !important;
    pointer-events: none;
    background: rgba(0, 0, 0, .25) !important;
    opacity: 0 !important;
    backdrop-filter: blur(3px) !important;
    -moz-backdrop-filter: blur(3px) !important;
    -webkit-backdrop-filter: blur(3px) !important;
    @extend %transition;
  }

  .EZDrawer__checkbox{
    &:checked{
      ~ .EZDrawer__overlay{
        opacity: 1 !important;
        pointer-events: auto;
      }
    }
  }

  .EZDrawer__container{
    height: auto !important;
    border-radius: $radiusOut $radiusOut 0 0;
    max-width: $maxWidth;
    margin: 0 auto;
    box-shadow: none !important;

    .drawer-close{
      position: relative;
      padding: $spacing - 6;

      &:after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        margin: 0 auto;
        width: 40px;
        height: 4px;
        background: rgba(0, 0, 0, .25);
        border-radius: 4px;
      }
    }

    .drawer-head{
      padding: $spacing;
      box-shadow: 0px 6px 11px -4px rgba(7, 7, 7, 0.13);
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;

      > span{
        display: block;
        margin-top: $spacingDiv4;
        color: $colorLabel;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }

    ul.resto-info{
      padding: $spacingDiv2 0 $spacing 0;
      margin: $spacingDiv2;
    
      > li{
        &.email,
        &.phone{
          > a{
            color: $colorText;

            &:hover{
              color: $colorPrimary;
            }
          }
        }
      }
    }
    
    .dialogue{
      padding: ($spacing + $spacingDiv2) $spacing ($spacing * 2) $spacing;

      .text{
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        // padding: 0 $spacing;
        max-width: 300px;
        margin: 0 auto;
        color: $colorLabel;

        > div{
          font-size: 18px;
          line-height: 22px;
          color: $colorText;
          font-weight: bold;
          margin: 0 0 $spacingDiv4 0;
        }

        > strong,
        > span{
          color: $colorText;
        }
      }

      > ul{
        padding: $spacing 0 0 0;
      }
    }
  }
}

.wrap-logo{
  display: inline-block;
  padding: 12px;
  background: #FFF;
  border-radius: $radiusIn;
  // width: auto;
  // height: 100px;
  // box-shadow: 0px 6px 11px -4px rgba(7, 7, 7, 0.05);
  box-shadow: 0px 4px 14px -2.5px rgba(7, 7, 7, 0.1);
  box-sizing: border-box;
  margin: 0 auto;

  img{
    display: block;
    // width: 100%;
    height: 76px;
    max-width: 150px;
    object-fit: contain;
  }
}

// CARD
.the-card{
  position: relative;
  background: $colorText url(./../../assets/img/icon/card-mask.svg) no-repeat center center;
  background-size: cover;
  margin: $spacing;
  color: #FFF;
  text-align: center;
  padding: 0 $spacingMul2 $spacingMul2 $spacingMul2;
  border-radius: $radiusOut;
  z-index: 1;
  
  > .the-table{
    position: relative;
    display: inline-block;
    background: rgba(255, 255, 255, .1);
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    border-radius: 0 0 $radiusOut $radiusOut;
    font-weight: 600;
    padding: 0 $spacingMul2;
    margin: 0 0 $spacing 0;
    box-shadow: 0 2px 0 $colorLabel;
    @extend %transition;

    &.success{
      box-shadow: 0 2px 0 $colorSuccess;
    }

    &.warning{
      box-shadow: 0 2px 0 $colorWarning;
    }

    &.error{
      box-shadow: 0 2px 0 $colorError;
    }

    &.primary{
      box-shadow: 0 2px 0 $colorPrimary;
    }

    > svg{
      margin: -2px 10px 0 0;
      width: 24px;
    }

    > span{
      position: absolute;
      width: 35px;
      height: 30px;

      &:before,
      &:after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      &:before{
        background: rgba(255, 255, 255, .1);
      }

      &:after{
        background: $colorText;
      }

      &.left{
        left: -35px;

        &:after{
          border-radius: 0 100% 0 0;
        }
      }

      &.right{
        right: -35px;

        &:after{
          border-radius: 100% 0 0 0;
        }
      }
    }
  }

  > .the-amount{
    font-size: 34px;
    line-height: 38px;
    padding: $spacing 0 0 0;
    font-weight: 100;

    strong{
      font-weight: normal;
    }

    > span{
      display: block;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      margin: $spacingDiv4 0 $spacingMul125 0;

      > strong{
        opacity: .5;
      }
    }

    > button{
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      color: $colorPrimary;
      margin: -5px 0 0 6px;

      &:hover{
        color: $colorPrimaryHover;
      }

      > svg{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        left: 0;
        transform-origin: center center;
        box-sizing: border-box;
      }

      &.animate{
        > svg{
          animation: rotating 1s infinite ease-in-out;
        }
      }
    }

    > ul.loading-btn{
      display: inline-block;
      vertical-align: middle;
      margin: -7.5px 0 0 0;
    }
  }

  .on-home{
    > ul.floating{
      padding-top: $spacing;

      > li{
        color: $colorLabel;

        > .btn-card{
          border-radius: $radiusIn + 1;
          border: 1px solid rgba(255, 255, 255, .20);
          overflow: hidden;

          > .btn{
            position: relative;
  
            > svg{
              margin: -3px 5px 0 0;
            }
  
            > span{
              position: absolute;
              height: 3px;
              width: 35%;
              margin: 0 auto;
              background: #FFF;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: 3px 3px 0 0;
            }
  
            &:after,
            &:before{
              content: '';
              position: absolute;
              height: 170px;
              margin-top: -50px;
              /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.5+100 */
              background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#80ffffff',GradientType=0 ); /* IE6-9 */
  
              top: 0;
              left: -50px;
              transform: rotate(45deg);
              transform-origin: center center;
              animation: shiny 5s infinite ease-in-out;
            }
  
            @keyframes shiny{
              0%, 80% { opacity: .25; left: -50%; }
              90% { opacity: .5; }
              100% { opacity: .25; left: 125%; }
            }
  
            &:after{
              width: 30px;
              margin-left: 10px;
            }
  
            &:before{
              width: 10px;
              // animation-delay: -0.025s;
            }
          }
        }

        &:nth-child(2){
          > .btn-card{
            > .btn{
              &:after,
              &:before{
                animation-delay: -2.5s;
              }
            }
          }
        }

        &.lay-50{
          > .btn-card{
            > .btn{
              line-height: 20px;
              height: auto;
              padding: $spacing;

              > svg{
                display: block;
                margin: 0 auto $spacingDiv2 auto;
              }

              > span{
                width: 45%;
              }
            }
          }
        }
      }
    }
  }

  .card-separate{
    height: 1px;
    background-image: linear-gradient(90deg, transparent, transparent 50%, $colorText 50%, $colorText 100%), linear-gradient(90deg, $colorText, #FFF, $colorText);
    background-size: 4px 1px, 100% 1px;
    opacity: .5;
  }

  > ul.floating{
    margin: $spacingMul125 (-$spacing) 0 (-$spacing);

    > li{
      font-size: 16px;
      line-height: 20px;
      padding: 0 $spacing;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      // &:first-child{
      //   border-right: 1px solid rgba(255, 255, 255, .5);
      // }

      > span{
        display: block;
        opacity: .5;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: $spacingDiv4;
        font-weight: normal;
      }
    }
  }
}

@keyframes rotating{
  0% { transform: rotate(0deg); }
  // 50% { opacity: .5; }
  100% { transform: rotate(360deg); }
}

.partial-label{
  position: relative;
  background: $colorWarning;
  margin: (-$spacing - 20) $spacing $spacing $spacing;
  text-align: center;
  font-weight: bold;
  color: #FFF;
  padding: ($spacingDiv2 + 22) $spacing ($spacingDiv2 + 2) $spacing;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 4px;
  border-radius: 0 0 $radiusOut $radiusOut;
  z-index: .5;
}

// INVOICE
ul.graphtop{
  margin: -5px 0 -5px 0;
  padding: 0 $spacingDiv2;

  > li{
    padding: 0;

    > div{
      background: $colorBg;
      width: 75%;
      height: 10px;
      border-radius: 0 0 20px 20px;
      margin: 0 auto;
    }
  }

  &.graphbottom{
    > li{
      > div{
        border-radius: 20px 20px 0 0;
        box-shadow: inset 0 10px 2.5px rgba(7, 7, 7, 0.04);
      }
    }
  }

  &.bill-update{
    padding-top: 0;

    > li{
      > div{
        background: #FFF;
      }
    }
  }
}

.billcon{
  background: #FFF;
  box-shadow: 0px 6px 11px -4px rgba(7, 7, 7, 0.13);

  > .billhead{
    padding: $spacingMul15 $spacing $spacingMul15 $spacing;
    font-weight: 600;
    color: $colorLabel;
    line-height: 16px;

    > strong{
      display: block;
      margin: 0 0 5px 0;
      font-size: 18px;
      line-height: 22px;
      color: $colorText;

      > button{
        width: 24px;
        height: 24px;
        float: right;
        color: $colorPrimary;

        // > svg{}

        &.animate{
          > svg{
            animation: rotating 1s ease-in-out;
          }
        }
      }
    }
  }

  > ul.billitem{
    padding: 0;
    margin: (-$spacingDiv2) 0 0 0;
    margin: 0;
    overflow: hidden;
    border-top: 1px solid $colorBg;
    
    > li{
      padding: $spacing $spacing 0 $spacing;
      // @extend %transition;

      // &:first-child{
      //   padding: 0 $spacing 0 $spacing;
      // }

      &.anim-enter{
        @extend %anim-enter;
      }
  
      &.anim-enter-active{
        @extend %anim-enter-active;
      }
  
      &.anim-enter-done{
        @extend %anim-enter-done;
      }
  
      &.anim-exit{
        @extend %anim-exit;
      }
  
      &.anim-exit-active{
        @extend %anim-exit-active;
      }

      &:last-child{
        padding: $spacing $spacing $spacingDiv2 $spacing;
      }

      > ul.theitem{
        margin-bottom: 0;
        padding: 0 0 $spacingDiv2 0;

        > li{
          font-size: 15px;
          line-height: 19px;

          &.theqty{
            text-align: right;
            color: $colorLabel;
          }

          &.theprice{
            text-align: right;
            color: $colorLabel;

            > strong{
              color: $colorText;
              font-weight: 600;
            }

            > div{
              position: relative;
              display: inline-block;
              font-size: 12px;
              line-height: 16px;

              &:before{
                content: '';
                position: absolute;
                top: 6px;
                left: 0;
                right: 0;
                height: 1px;
                width: 100%;
                background: rgba($colorLabelRGB, .75);
              }

              > strong{
                font-weight: normal;
              }
            }
          }

          > button{
            text-align: left;
            font-size: 15px;
            line-height: 19px;

            svg{
              color: $colorLabel;
              width: 16px;
              height: 16px;
              margin: 0 0 0 5px;
              padding: 3px;
              box-sizing: border-box;
              @extend %transition;

              // &.icon-note{
              //   display: block;
              //   width: 14px;
              //   height: 14px;
              //   margin: 2px 0 0 0;
              //   padding: 0;
              // }

              // &.icon-disc{
              //   display: block;
              //   width: 14px;
              //   height: 14px;
              //   margin: 2px 0 0 0;
              //   padding: 0;
              // }
            }

            > ul{
              margin: 0 (-$spacingDiv4);

              > li{
                display: inline-block;
                padding: 0 $spacingDiv4;

                > svg{
                  display: block;
                  width: 14px;
                  height: 14px;
                  margin: 2px 0 0 0;
                  padding: 0;
                }
              }
            }
          }
        }
      }
      
      > ul.thecustom{
        margin-top: (-$spacingDiv2);
        margin-bottom: 0;
        padding: 0 0 $spacingDiv2 0;

        > li{
          > ul.theitem{
            > li{
              font-size: 14px;
              line-height: 18px;
    
              &:nth-child(1){
                text-align: center;
                padding-right: 0;
              }
    
              &.theqty{
                text-align: right;
                color: $colorLabel;
              }
    
              &.theprice{
                text-align: right;
                color: $colorLabel;
    
                > strong{
                  font-weight: normal;
                }
              }
    
              > button{
                text-align: left;
                font-size: 14px;
                line-height: 18px;
    
                > svg{
                  color: $colorLabel;
                  width: 16px;
                  height: 16px;
                  margin: 0 0 0 5px;
                  padding: 3px;
                  box-sizing: border-box;
                  @extend %transition;
                }
              }
            }
          }

          &.special{
            > ul{
              margin: -$spacingDiv4;
              
              > li{
                padding: $spacingDiv4;

                > div{
                  font-weight: 600;
                  color: $colorLabel;
                  line-height: 16px;
                  background: #FFF;
                  padding: $spacing;
                  border-radius: $radiusIn;
      
                  > svg{
                    width: 14px;
                    height: 14px;
                    margin: -2px 5px 0 0;
                  }
      
                  > div{
                    font-weight: normal;
                    color: $colorText;
                    margin-top: $spacingDiv2;
                    font-size: 12px;
                    line-height: 16px;
                  }
                }
              }
            }
          }

          > ul.thecustom{
            margin: $spacingDiv4 (-$spacingDiv2) (-$spacingDiv4) (-$spacingDiv2);
            overflow: hidden;
    
            > li{
              padding: $spacingDiv4 $spacingDiv2;
              > ul.theitem{
                > li{
                  font-size: 13px;
                  line-height: 17px;
        
                  &:nth-child(1),
                  &:nth-child(2){
                    text-align: center;
                    padding-right: 0;
                  }
        
                  &.theqty{
                    text-align: right;
                    color: $colorLabel;
                  }
        
                  &.theprice{
                    text-align: right;
                    color: $colorLabel;
        
                    > strong{
                      font-weight: normal;
                    }
                  }
        
                  // > button{
                  //   text-align: left;
                  //   font-size: 14px;
                  //   line-height: 18px;
        
                  //   > svg{
                  //     color: $colorLabel;
                  //     width: 16px;
                  //     height: 16px;
                  //     margin: 0 0 0 5px;
                  //     padding: 3px;
                  //     box-sizing: border-box;
                  //   }
                  // }
                }
              }
            }
          }

          &.open{
            > ul.theitem{
              > li{
                &:nth-child(2){
                  font-weight: 600;
                }
    
                > button{
                  font-weight: 600;
    
                  > svg{
                    &.icon-chev{
                      transform: rotate(-180deg);
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.open{
        background: rgba($colorLabelRGB, .075);
        box-shadow: inset 2px 0 0 $colorPrimary;

        > ul.theitem{
          > li{
            &:nth-child(1){
              font-weight: 600;
            }

            > button{
              font-weight: 600;

              > svg{
                &.icon-chev{
                  transform: rotate(-180deg);
                }
              }
            }
          }
        }
      }
    }
  }

  > .billsum{
    position: relative;
    border-top: 1px solid $colorBg;
    padding: $spacingMul15 $spacing;

    &:before,
    &:after{
      content: '';
      position: absolute;
      width: 10px;
      height: 20px;
      border-radius: 10px;
      background: $colorBg;
      top: -10px;
    }

    &:before{
      left: -5px;
    }

    &:after{
      right: -5px;
    }

    &.no-separ{
      &:before,
      &:after{
        display: none;
      }
    }

    > ul{
      overflow: hidden;

      > li{
        padding: $spacingDiv4 $spacingDiv2;

        &.anim-enter{
          @extend %anim-enter;
        }
    
        &.anim-enter-active{
          @extend %anim-enter-active;
        }
    
        &.anim-enter-done{
          @extend %anim-enter-done;
        }
    
        &.anim-exit{
          @extend %anim-exit;
        }
    
        &.anim-exit-active{
          @extend %anim-exit-active;
        }

        &.last-normal{
          padding: 0 $spacingDiv2 $spacingDiv4 $spacingDiv2;
        }
        
        .staff{
          // margin: 5px 0 0 0;
          color: $colorLabel;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          
          > span{
            color: $colorText;
          }
          > img{
            display: inline-block;
            margin: -3px 0 0 5px;
            width: 20px;
            height: 20px;
          }
        }
        > ul{
          overflow: hidden;

          > li{
            font-size: 14px;
            line-height: 18px;

            span{
              color: $colorLabel;
            }

            &:last-child{
              text-align: right;
              color: $colorLabel;

              > strong{
                color: $colorText;
                font-weight: 600;
              }
            }
          }
          
          &.primary{
            color: $colorPrimary;

            > li{
              &:last-child{
                color: $colorPrimary;

                > strong{
                  color: $colorPrimary;
                }
              }
            }
          }
        }

        &.subtotal{
          padding: $spacingDiv2 $spacingDiv2 $spacingDiv8 $spacingDiv2;

          > ul{
            > li{
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        &.disc{
          > ul{
            > li{
              &:last-child{
                color: $colorPrimary;

                > strong{
                  color: $colorPrimary;
                }
              }
            }
          }
        }

        &.total{
          padding: $spacingDiv2;

          .separation-dashed{
            margin: 0 0 $spacing 0;
          }

          > ul{
            > li{
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
            }
          }

          &.normal{
            > ul{
              > li{
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  > button{
    position: relative;
    display: block;
    color: $colorPrimary;
    height: 50px;
    font-size: 14px;
    
    .separation-dashed{
      display: none;
      position: absolute;
      top: 0;
      left: $spacing;
      right: $spacing;
    }

    &.on-open{
      margin-top: (-$spacingDiv2);

      .separation-dashed{
        display: block;
      }
    }

    &.on-open-item{
      .separation-dashed{
        display: block;
      }
    }
  }

  &.bill-hide{
    > ul.billitem{
      > li{
        display: none;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3){
          display: block;
        }
      }
    }

    > .billsum{
      display: none;
    }
  }

  &.bill-hide-item{
    > ul.billitem{
      > li{
        display: none;

        &:nth-child(1){
          display: block;
        }
      }
    }
  }

  &.bill-update{
    > .billsum{
      border-top: none;

      &:before,
      &:after{
        display: none;
      }

      > ul{
        > li{
          > ul{
            > li{
              &:first-child{
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

// RESTAURANT INFO
ul.resto-info{
  margin: (-$spacingDiv4) 0;
  overflow: hidden;
  // padding: $spacing;

  > li{
    padding: $spacingDiv4 0;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    
    &.address{
      color: $colorLabel;
      padding-top: 0;
      padding-bottom: $spacing;
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
    }

    > a{
      font-size: 14px;
      line-height: 18px;
      color: $colorLabel;
      font-weight: normal;

      &:hover{
        color: $colorPrimary;
      }
    }
    &.div-socmed{
      // border-top: 1px solid #FFF;
      // padding: $spacingDiv2 0 0 0;
      // margin: $spacingDiv2 0 0 0;

      padding: 0;
      margin: $spacingDiv2 0;
      height: 1px;
      background-image: linear-gradient(90deg, transparent, transparent 50%, $colorBg 50%, $colorBg 100%), linear-gradient(90deg, $colorBg, $colorText, $colorBg);
      background-size: 4px 1px, 100% 1px;
      opacity: .5;
    }

    &.socmed{
      color: $colorLabel;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      padding-top: $spacing;
      
      > ul{
        margin: 0 (-$spacingDiv4) (-$spacingDiv4) (-$spacingDiv4);

        > li{
          display: inline-block;
          padding: $spacingDiv4;

          > a{
            display: block;
            color: $colorText;
            font-size: 12px;
            line-height: 12px;
            padding: ($spacingDiv2 + 2) $spacingDiv15 $spacingDiv2 $spacingDiv15;
            // background: rgba($colorLabelRGB, .05);
            background: #F7F8FA;
            border-radius: $radiusInDiv2;

            // &:hover{
            //   background: $colorPrimary;
            //   color: #FFF;
            // }

            &.fb{
              color: $colorFb;
              // &:hover{
              //   background: $colorFb;
              //   color: #FFF;
              // }
            }
            &.ig{
              color: $colorIg;
              // &:hover{
              //   background: $colorIg;
              //   color: #FFF;
              // }
            }
            &.tw{
              color: $colorTw;
              // &:hover{
              //   background: $colorTw;
              //   color: #FFF;
              // }
            }

            > svg{
              width: 18px;
              height: 18px;
              margin: -3px 1px 0 0;
            }
          }
        }
      }
    }
  }
}

// POWERED
.has-power{
  padding-bottom: $spacing + 71;
}

.powered{
  max-width: $maxWidth;
  margin: -71px auto 0 auto;
  padding: $spacing $spacing $spacingMul15 $spacing;
  text-align: center;
  background: rgba($colorLabelRGB, .05);
  border-radius: $radiusOut $radiusOut 0 0;
  font-weight: 600;
  font-size: 9px;
  line-height: 13px;
  color: rgba($colorLabelRGB, .75);
  letter-spacing: 2px;
  box-sizing: border-box;

  > svg{
    display: block;
    width: 160px;
    margin: 0 auto;
  }
}

// MISC CONTENT
.misc-content{
  position: absolute;
  max-width: $maxWidth;
  top: 50%;
  transform: translate(0, -50%);
  padding: $spacing;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  color: $colorText;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-sizing: border-box;

  > .wrap-img{
    position: relative;
    width: 60%;
    margin: 0 auto 100px auto;

    &:before{
      content: '';
      opacity: .2;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0+75 */
      background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(ellipse at center,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }

    > img{
      position: relative;
      display: block;
      max-height: 200px;
      object-fit: contain;
      margin: 0 auto;
    }
  }

  > span{
    display: block;
    margin: $spacingDiv4 auto 0 auto;
    font-size: 14px;
    line-height: 18px;
    color: $colorLabel;
    width: 80%;
  }

  > div{
    margin: 40px 0 0 0;

    > button{
      color: $colorPrimary;
      font-size: 14px;

      &:hover{
        color: $colorPrimaryHover;
      }

      > svg{
        width: 16px;
        height: 16px;
        margin: -2px 5px 0 0;
      }
    }

    > span{
      display: block;
      margin-top: $spacingDiv2;
      font-size: 12px;
      line-height: 16px;
      color: $colorLabel;
    }

    &.loading{
      margin: 0 0 $spacing 0;
      padding: 0;
    }
  }
}

.react-pdf__Document{
  position: relative;
  max-width: 100%;

  .react-pdf__Page{
    max-width: 100%;

    canvas{
      max-width: 100%;
      height: auto !important;
    }
  }
}

.iframe{
  position: relative;
  margin: -48px 0 0 0;
  padding: 48px 0 0 0;

  > iframe{
    position: absolute;
    padding: 48px 0 0 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
  }
}

.card-frame {
  border: solid 1px #8c9297;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 8px;
  height: 40px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
  background-color: #e9e8e8;
}

.card-frame.frame--rendered {
  opacity: 1; /* Prevents iFrame rendering issue */

  /* Reminder: consider removal of 'rendered' */
  /* event passing to Merchant page */
}

.card-frame.frame--rendered.frame--focus {
  border: solid 1px #13395e;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.card-frame.frame--rendered.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.checkout-error-message {
  // color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

.success-payment-message {
  color: #13395e;
  line-height: 1.4;
}
.token {
  color: #b35e14;
  font-size: 0.9rem;
  font-family: monospace;
}

@media screen and (min-width: 31rem) {
  .one-liner {
    flex-direction: row;
  }

  .card-frame {
    width: 100%;
    margin-bottom: 0;
  }
}

.checkout-popup-overlay-content{
  border-radius: 10px !important;

  .checkout-input-con{
    padding: 20px;
    font-size: 13px;
    line-height: 18px;
  }

  .checkout-popup-foot{
    background: #EEE;
    padding: 20px;

      #checkout-pay-button {
        border-radius: 3px;
        font-weight: 500;
        height: 40px;
        font-size: 14px;
        line-height: 40px;
      }

      #checkout-pay-button[disabled] {
        background: $colorPrimary !important;
        color:#fff
      }
  }

}


.checkout-input-con{
  padding: 20px;
  font-size: 13px;
  line-height: 18px;
}

.checkout-popup-foot{
  padding: 10px 20px 20px 20px;

    #checkout-pay-button[disabled] {
      background: $colorPrimary !important;
      color:#fff
    }
}

.checkout-sync-popup-content {
  font-size: 13px;
  // border: 1px solid black;
  text-align: center;
}


[dir="rtl"]{
  header{
    > ul.head-nav{
      &.on-left{
        left: auto;
        right: $spacing;
      }

      &.on-right{
        left: $spacing;
        right: auto;
      }

      > li{
        > a,
        > button{
          &.close{
            transform: scaleX(-1);
          }
        }
      }
    }

    .head-mid{
      > svg{
        margin: -3px 10px 0 5px;
        border: none;
        border-right: 1px solid $colorLabel;
        padding: 0 10px 0 0;
      }
    }
  }
  
  ul.floating{
    > li{
      float: right;
    }
  }
  
  .link-look{
    > svg{
      margin: -3px 0 -2px 5px;
    }
  }

  .the-card{
    > .the-table{
      > svg{
        margin: -2px 0 0 10px;
      }
    }

    .the-amount{
      > button{
        margin: -5px 6px 0 0;

        > svg{
          transform: scaleX(-1);
        }

        &.animate{
          > svg{
            animation: rotatingAr 1s ease-in-out;
          }
        }
      }
    }
  }

  .billcon{
    > .billhead{
      > strong{
        > button{
          float: left;

          > svg{
            transform: scaleX(-1);
          }

          &.animate{
            > svg{
              animation: rotatingAr 1s ease-in-out;
            }
          }
        }
      }
    }

    > ul.billitem{
      > li{
        > ul.theitem{
          > li{
            &.theqty{
              text-align: left;
            }
  
            &.theprice{
              text-align: left;
            }

            > button{
              text-align: right;

              svg{
                margin: 0 5px 0 0;
              }
            }
          }
        }

        > ul.thecustom{
          > li{
            &.special{
              > ul{
                > li{
                  > div{
                    > svg{
                      margin: -2px 0 0 5px;
                    }
                  }
                }
              }
            }

            > ul.theitem{
              > li{
                &:nth-child(1){
                  padding: $spacingDiv2;
                  padding-left: 0;
                }

                &.theqty{
                  text-align: left;
                }

                &.theprice{
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }

    > .billsum{
      > ul{
        > li{
          > ul{
            > li{
              &:last-child{
                text-align: left;
              }
            }
          }
        }
      }
    }

    &.bill-update{
      > .billsum{
        > ul{
          > li{
            > ul{
              > li:first-child{
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  ul.forming{
    > li{
      &.has-label-left{
        input[type="text"],
        input[type="password"],
        input[type="number"]{
          // text-align: right;
          padding: 0 ($spacingDiv2 + 45) 0 $spacing;
        }
      }

      .input-notif{
        right: auto;
        left: $spacingMul15;
      }

      .label-left{
        left: auto;
        right: $spacingDiv2 - 3;
        padding: 0 $spacing 0 0;
      }

      label{
        &.text{
          left: auto;
          right: $spacing;
        }
      }

      &.is-focus{
        label{
          &.text{
            right: $spacingMul125;
          }
        }
      }
    }
  }

  .react-select__menu-portal{
    .react-select__menu{
      .react-select__menu-list{
        .react-select__option{
          > .staff{
            margin: -2px 0 0 5px;
          }
        }
      }
    }
  }

  .misc-content{
    > .wrap-img{
      transform: scaleX(-1);
    }

    > div{
      > button{
        > svg{
          margin: -2px 0 0 5px;
          transform: scaleX(-1);
        }
      }
    }
  }
}

@keyframes rotatingAr{
  0% { transform: scaleX(-1) rotate(0deg); }
  // 50% { opacity: .5; }
  100% { transform: scaleX(-1) rotate(360deg); }
}